import { graphql, useStaticQuery } from "gatsby";

/**
 * Custom hook that uses GraphQL to fetch a WordPress page by slug
 *
 * @param {string} slug - The slug of the WordPress page to fetch
 * @returns {Object} The WordPress page data
 */
const usePageQuery = (slug) => {
  // Use Gatsby's useStaticQuery to fetch all pages at build time
  const { allWpPage } = useStaticQuery(graphql`
    query WordPressPageQuery {
      allWpPage {
        edges {
          node {
            id
            title
            content
            slug
            date(formatString: "MMMM DD, YYYY")
            seo {
              title
              metaDesc
            }
            featuredImage {
              node {
                title
                localFile {
                  publicURL
                }
              }
            }
            pages {
              h1Title
            }
          }
        }
      }
    }
  `);

  // Find the page that matches the provided slug
  const page = allWpPage.edges.find(({ node }) => node.slug === slug);

  // Return the page data or null if not found
  return page ? page.node : null;
};

export default usePageQuery;
