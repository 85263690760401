import React from "react";
import usePageQuery from "../../hooks/usePageQuery";

const ServiceAreasPrimary = () => {
  const page = usePageQuery("service-areas");

  return (
    <div
      className="wp-content"
      dangerouslySetInnerHTML={{
        __html: page.content,
      }}
    />
  );
};

export default ServiceAreasPrimary;
